html {
  height:100%;
  margin:    0 auto;
  max-width: 950px;
  background-image: linear-gradient(to bottom right, red yellow);


}

body {
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;



  font-family: 'Reggae One', cursive;
  height: auto;
  background-image: linear-gradient(to bottom right, #0d0000,#350000);

  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #FFFFFF;
}

.navbar-customclass {
  background-color: black;
}

.navbar-customclass .navbar-brand,
.navbar-customclass .navbar-text{
  color: #FFFFFF
}
.navbar-customclass .navbar-nav .nav-link {
  color: rgba(255,255,255,.6);
}
/* navigation links anchor hover and active styles */
.navbar-customclass .nav-item.active .nav-link,
.navbar-customclass .nav-item:hover .nav-link {
  color: #ffffff;
}

.topbox-bk{
 background-color: none; 


}

.blue-border{
  border-style: none;
  border-width: 6px;
  border-radius: 25px;
  border-color: #FF3131;
  height: 100%;
  width: 100%;
  min-height: 100%;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
}

p{
  Font-family: 'Reggae One', cursive;
}

.mint-container{
  border-style: none;
  border-width: 6px;
  border-radius: 25px;
  border-color: steelblue;
  text-align: center;
  vertical-align:text-bottom;
  margin: auto;
  padding-top: 3%;
  padding-bottom: 5%;
  font-size: 1.9em;

}

.shadowfilter {
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
   filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));}

   .bottleimage {
    width: 500px;}


@media (min-width:576px) {    
.boxed{
  border: 10px solid #FF3131; 
  border-width: 6px;
  border-radius: 25px;
  box-shadow: 10px 5px 1px crimson;
}}
  .container.custom-container {
padding: 0;
  }



  
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

  @media (min-width:950px) {  
  .body-border{
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: .5%;
    border-style: solid;
    border-width: 5px;
    border-color: black;
    height: 100%;
    width: 100%;
    min-height: 100%;
  }}
  